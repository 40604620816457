import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { ReactComponent as HistoryIcon } from "../../assets/icons/workHistory.svg";
import WorkHistoryItem from "./components/WorkHistoryItem/WorkHistoryItem";

import styles from "./styles.module.scss";

const WorkHistory = () => {
  const { workHistory } = useSelector((state) => state.workHistory);

  const formattedHistoryDates = useMemo(() => {
    const mutableWorkHistory = [...workHistory];
    const todayISO = dayjs().startOf('day').format('YYYY-MM-DDT00:00:00+00:00');
    const containsToday = mutableWorkHistory.some(item => dayjs(item.date).isSame(todayISO));

    if (!containsToday) mutableWorkHistory.unshift({ date: todayISO })

    return (mutableWorkHistory ?? [])?.map((obj) => obj?.date);
  }, [workHistory])

  return <div className={styles.workHistory}>
    <div className={styles.workHistoryHeader}>
      <p>WORK HISTORY</p>
      <HistoryIcon />
    </div>
    <div className={styles.workHistoryContainer}>
      {formattedHistoryDates?.map((date) =>
        <WorkHistoryItem date={date} />
      )
      }
    </div>
  </div>
}

export default WorkHistory