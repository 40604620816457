import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';

import { WorksheetJob } from './components';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '../../hooks/useQuery';

import { ReactComponent as QuickLinksIcon } from '../../assets/icons/quickLinks.svg'
import { ReactComponent as WorkHistoryIcon } from '../../assets/icons/workHistory.svg';
import CloseIcon from '../../assets/icons/closeIcon.png';

import WorkHistory from '../WorkHistory/WorkHistory';
import QuickLinks from '../QuickLinks/QuickLinks';

import styles from './styles.module.scss';
import { getWorksheetByDate } from '../../store/slices/worksheetSlice';

const RENDERED_COMPONENT = {
  WORK_HISTORY: 'WORK_HISTORY',
  QUICK_LINKS: 'QUICK_LINKS'
};

const Worksheet = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const { worksheetList } = useSelector((state) => state.worksheet);
  const [renderedComponentName, setRenderedComponentName] = useState('');
  const selectedDate = query.get("date") ?? dayjs().startOf('day').format('YYYY-MM-DDT00:00:00+00:00');

  useEffect(() => {
      dispatch(getWorksheetByDate(selectedDate))
  }, [selectedDate]);

  const renderedComponent = useMemo(() => {
    switch (renderedComponentName) {
      case RENDERED_COMPONENT.QUICK_LINKS:
        return {
          component: <QuickLinks />,
          title: 'QUICK LINKS',
          icon: <QuickLinksIcon />
        }
      case RENDERED_COMPONENT.WORK_HISTORY:
        return {
          component: <WorkHistory />,
          title: 'WORK HISTORY',
          icon: <WorkHistoryIcon />
        }
      default: return null
    }
  }, [renderedComponentName]);

  return (
    <div className={styles.worksheet}>
      {!renderedComponent ?
        <>
          <div className={styles.worksheetHeader}>
            <div className={styles.worksheetHeaderTitle}>
              <p>WORKSHEET</p>
              <p>{dayjs(selectedDate).format('MMMM D, YYYY')}</p>
            </div>
            <div className={styles.worksheetHeaderLinks}>
              <WorkHistoryIcon onClick={() => setRenderedComponentName(RENDERED_COMPONENT.WORK_HISTORY)} />
              <QuickLinksIcon onClick={() => setRenderedComponentName(RENDERED_COMPONENT.QUICK_LINKS)} />
            </div>
          </div>
          <div className={styles.worksheetContent}>
            {worksheetList?.map((worksheetObj, index) => <WorksheetJob worksheet={worksheetObj} index={index} key={index} />)}
          </div>
        </>
        :
        <div className={styles.renderedComponentContainer}>
          <div className={styles.worksheetHeader}>
            <div className={styles.worksheetHeaderTitle}>
              <p>{renderedComponent?.title}</p>
              <p></p>
            </div>
            <div className={cx(styles.worksheetHeaderLinks, styles.activeSVG)}>
              {renderedComponent?.icon}
              <img onClick={() => setRenderedComponentName('')} src={CloseIcon} alt={'close-icon'} />
            </div>
          </div>
          {renderedComponent?.component}
        </div>
      }
    </div>
  )
}

export default Worksheet