import { Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useRef } from 'react';

import { useAuth } from './context/AuthContext';

import {ReactComponent as Logo} from './assets/icons/logo.svg';
import Dashboard from './page/Dashboard/Dashboard';
import LoginPage from './page/LoginPage/LoginPage';

import styles from './styles.module.scss';

const UnAuthApp = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}

const AuthApp = () => {
  const { logout, token } = useAuth();

  useEffect(() => {
    if(token) {
      (async () => {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      })();
    }
  }, [token]);

  return (
    <>
      <div className={styles.appHeader}>
        <Logo/>
        <p className={styles.appHeaderLogOut} onClick={logout}>Log out</p>
      </div>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </>
  );
}

const App = () => {
  const { currentUser, logout, token } = useAuth();
  const interceptor = useRef();

  (async () => {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.post["Content-Type"] =
      "application/json";
  })();
  
  useEffect(() => {
    (interceptor.current !== null) && axios.interceptors.request.eject(interceptor?.current)
    interceptor.current = axios.interceptors.request.use(async (config) => {
      if (!currentUser) {
        return config
      }
      config.headers["Authorization"] = `Bearer ${token}`;
      if (token === null) {
        logout();
        return Promise.reject();
      }
      return config;
    });
  }, [currentUser])

  return (
    <div className={styles.app}>
      {currentUser ? <AuthApp /> : <UnAuthApp />}
    </div>
  );
}

export default App;
