import axios from 'axios';
import { toast } from 'react-toastify';

const request = async (method, endpoint, data = null, headers = {}, params = null) => {
  try {
    const config = {
      headers,
      ...(method === 'GET' && params && { params }),
      ...(data && { data: new URLSearchParams(data) }),
    };

    const response = await axios({ method, url: endpoint, ...config });
    return response.data;
  } catch (error) {
    toast.error(
      error?.response?.data?.error?.message ||
      error.message ||
      "Error Occurred",
      {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
    return Promise.reject(error.response)
  }
};

export const postData = (endpoint, data, headers = {}) => request('POST', endpoint, data, headers);
export const fetchData = (endpoint, headers = {}, params = null) => request('GET', endpoint, null, headers, params);
