import styles from './styles.module.scss';

const WorksheetTrackerUpdate = ({ updatedTrackers }) => {

  return (
    <div className={styles.worksheetUpdatedTracker}>
      <div className={styles.worksheetUpdatedTrackerContainer}>
        <p>Buyer Trackers Updated</p>
        <ul className={styles.worksheetUpdatedTrackerContainerDescription}>
          {updatedTrackers?.map((item, index) => (
            <div className={styles.worksheetUpdatedTrackerItem}>
              <div className={styles.worksheetUpdatedTrackerItemContainer}>
                <a href={item?.changelog_url} target='_blank'>{item?.name}</a>
                <div className={styles.worksheetUpdatedTrackerSeparator} />
                <p>{item?.new_properties}</p>
                <div className={styles.worksheetUpdatedTrackerSeparator} />
                <p>{item?.updated_properties}</p>
                <div className={styles.worksheetUpdatedTrackerSeparator} />
                [<a href={item?.changelog_url} target='_blank'>View</a>]
              </div>
            </div>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default WorksheetTrackerUpdate