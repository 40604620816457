import { Button } from 'antd';
import cx from 'classnames';
import { useQuery } from '../../../../hooks/useQuery';

import WorksheetTask from '../WorksheetTask/WorksheetTask';
import WorksheetTracker from '../WorksheetTracker/WorksheetTracker';
import WorksheetProcess from '../WorksheetProcess/WorksheetProcess';
import WorksheetResults from '../WorksheetResults/WorksheetResults';
import WorksheetTrackerUpdate from '../WorksheetTrackerUpdate/WorksheetTrackerUpdate';

import styles from './styles.module.scss';

const WorksheetJob = ({ worksheet, index }) => {
  const query = useQuery();

  const selectedDate = query.get("date");

  return <div className={cx(styles.worksheetJob, (!selectedDate && index === 0) ? styles.worksheetJobActive : '')}>
    <div className={styles.worksheetJobDetails}>
      <div className={styles.worksheetJobHeader}>
        <p>{worksheet?.headline}</p>
        <p>{worksheet?.description}</p>
      </div>
      {/* <Button>DISCUSS</Button> */}
    </div>
    <div className={styles.worksheetJobTasks}>
      {worksheet?.process && <WorksheetProcess process={worksheet?.process} />}
      {worksheet?.results && <WorksheetResults results={worksheet?.results} />}
      {worksheet?.updated_trackers && <WorksheetTrackerUpdate updatedTrackers={worksheet?.updated_trackers} />}
      {worksheet?.tasks?.map((obj, index) => <WorksheetTask items={obj.items} headline={obj?.headline} key={index} />)}
      {worksheet?.tracker_status && <WorksheetTracker tracker={worksheet?.tracker_status} />}
    </div>
  </div>
}

export default WorksheetJob