import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import TextArea from 'antd/es/input/TextArea';
import { ReactComponent as Microphone } from '../../../../assets/icons/microphone.svg'
import { ArrowUpOutlined } from '@ant-design/icons';

import { appendMessage, setLoadingState } from '../../../../store/slices/chatSlice';
import { SocketContext } from '../../../../websocket/WebsocketWrapper';
import { CHAT_SENDER } from '../../../../utils/chatUtils';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

import styles from './styles.module.scss';

const ChatInputComponent = ({ isMobileView, heightStage }) => {
  const dispatch = useDispatch();

  const { websocketConnected, loading } = useSelector((state) => state.chat);

  const { websocket } = useContext(SocketContext);
  const [messages, setMessage] = useState('');
  const [minRows, setMinRows] = useState(1);
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [isListening, setIsListening] = useState(false);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();
  const containerRef = useRef();
  const textareaRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect();
        setSize({ width, height });
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    };

    return () => resizeObserver.disconnect();
  }, []);

  const sendMessage = useCallback(async () => {
    if (!!messages.length) {
      dispatch(setLoadingState(true))
      resetTranscript();
      websocket.emit('new_message', {
        message: messages,
        id: `${websocket.id}${Math.random()}`,
        socketID: websocket.id,
      });
      dispatch(appendMessage({ sender: CHAT_SENDER.USER, message: messages }));
      setMessage('');
    }
    setIsListening(false);
  }, [messages, websocket, setMessage, dispatch, setLoadingState, setIsListening]);

  useEffect(() => { transcript && setMessage(transcript) }, [transcript])

  useEffect(() => {
    const updateRows = () => {
      if (containerRef.current) {
        const containerHeight = containerRef.current.offsetHeight;
        const lineHeight = 22;
        setMinRows(Math.floor(containerHeight / lineHeight));
      }
    };
    updateRows();
    window.addEventListener("resize", updateRows);
    return () => {
      window.removeEventListener("resize", updateRows);
    };
  }, [websocketConnected, size]);

  const handleListening = useCallback(() => {
    if (listening) {
      SpeechRecognition.stopListening();
      setTimeout(() => sendMessage(), 800);
    } else {
      setIsListening(true)
      SpeechRecognition.startListening({ continuous: true })
      resetTranscript()
    }
  }, [listening, sendMessage, resetTranscript, SpeechRecognition, setIsListening])

  useEffect(() => {
    if (!isMobileView || heightStage != 0) {
      textareaRef?.current && !loading && textareaRef?.current.focus();
    }
  }, [loading]);

  return <div className={styles.chatInput}>
    {websocketConnected ?
      <>
        <div className={styles.chatInputTextArea} >
          <div style={{ height: '100%', width: '100%' }} ref={containerRef}>
            {isListening ?
              <div className={styles.chatInputTextAreaPlaceholder}>
                <p><b>LISTENING</b> - Release to send.</p>
              </div>
              :
              <TextArea
                disabled={loading}
                id={"textarea"}
                autoSize={{ minRows: minRows }}
                ref={textareaRef}
                value={messages}
                onChange={(e) => setMessage(e.target.value)}
                onPressEnter={(e) => {
                  e.preventDefault()
                  !!messages.length && sendMessage()
                }}
              />
            }
          </div>
          <div className={styles.chatInputTextAreaSend} onClick={sendMessage}>
            <ArrowUpOutlined />
          </div>
        </div>
        <div className={styles.chatInputMicrophone} onMouseDown={() => handleListening()} onMouseUp={() => handleListening()}>
          <Microphone />
          <p>{listening ? 'Listening' : 'Press & Hold'}</p>
        </div>
      </> :
      <div style={{ textAlign: 'center', width: '100%' }}>
        <p> Websocket is disconnected</p>
      </div>
    }
  </div>
}

export default ChatInputComponent;